<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.menu.payroll") }}
          <v-spacer />
          <v-col cols="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="0"
              color="primary"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field v-model="formattedSelectedDates" :label="$t('global.year')" v-on="on" readonly />
              </template>
              <v-date-picker
                v-model="selectedDates"
                :allowed-dates="allowedDates"
                multiple
                color="primary"
                type="month"
              />
            </v-menu>
          </v-col>
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [12, 25, 50, 100] }"
          class="elevation-1"
        >
          <template slot="item" slot-scope="row">
            <tr :active="row.selected">
              <td>
                {{ row.item.date | month }}
              </td>

              <td>
                <file-download-button v-if="row.item.url" :url="row.item.url" />
              </td>

              <td>
                {{ row.item.createdDateTime | formattedDateTimeMinute }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description Performance Component Logic
   * @date 28.07.2020
   */

  import { PAYROLL } from "../query";

  export default {
    name: "Payroll",
    data: vm => ({
      selectedDates: [vm.$moment().format("YYYY-MM")],
      menu: false,
      table: {
        loading: false,
        data: [],
        criteria: {
          employee: vm.$store.state.auth.user.id,
          beginDate: vm
            .$moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: vm
            .$moment()
            .endOf("month")
            .format("YYYY-MM-DD")
        },
        options: {},
        pagination: {
          rowsPerPage: 10,
          sortBy: "date",
          descending: true
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("hr.payroll.month"),
            align: "left",
            sortable: true,
            value: "date",
            class: ["primary--text"]
          },

          {
            text: vm.$t("hr.payroll.file"),
            align: "left",
            sortable: false,
            value: "url",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.payroll.created_date"),
            align: "left",
            sortable: false,
            value: "url",
            class: ["primary--text"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
        },
        deep: true
      },
      "table.criteria": {
        handler() {
          this.fetchItems();
        },
        deep: true
      },
      selectedDates(dates) {
        const sortedDates = this.sortDates(dates);
        let beginDate = null;
        let endDate = null;
        if (sortedDates.length) {
          beginDate = this.$moment(sortedDates[0])
            .startOf("month")
            .format("YYYY-MM-DD");
          endDate = this.$moment(sortedDates[sortedDates.length - 1])
            .endOf("month")
            .format("YYYY-MM-DD");
        } else {
          beginDate = this.$moment()
            .startOf("month")
            .format("YYYY-MM-DD");
          endDate = this.$moment()
            .endOf("month")
            .format("YYYY-MM-DD");
        }
        this.table.criteria.beginDate = beginDate;
        this.table.criteria.endDate = endDate;
      }
    },
    computed: {
      formattedSelectedDates() {
        const sortedDates = this.sortDates(this.selectedDates);
        const length = sortedDates.length;
        switch (length) {
          case 0:
            return "";
          case 1:
            return this.$moment(sortedDates[0]).format("YYYY-MMMM");
          default:
            return `${this.$moment(sortedDates[0]).format("YYYY-MMMM")} - ${this.$moment(
              sortedDates[length - 1]
            ).format("YYYY-MMMM")}`;
        }
      }
    },
    methods: {
      allowedDates(date) {
        if (this.selectedDates.length <= 1) {
          return true;
        } else {
          return this.selectedDates.includes(date);
        }
      },
      sortDates(dates) {
        if (dates.length > 0) {
          const unsortedDates = this.$helpers.cloneDeep(dates);
          unsortedDates.sort((a, b) => {
            return this.$moment(a).diff(this.$moment(b), "months");
          });
          return unsortedDates;
        }
        return [];
      },
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: PAYROLL,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!errors || !data.error) {
              this.table.data = data.filterWithPaginatePayrolls.content || [];
              this.table.totalItems = data.filterWithPaginatePayrolls.pageInfo.totalElements || 0;
              this.table.totalPages = data.filterWithPaginatePayrolls.pageInfo.totalPages || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>

<style scoped></style>
